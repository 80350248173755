import React from "react";

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import GroupsIcon from '@mui/icons-material/Groups';


export const logo = 'https://imagedelivery.net/beA4vEIaSpPgFqjqWBwIgQ/a9596514-30ae-4970-6df0-37afd0769f00/public';

export const categories = [
  { name: 'Home', icon: <HomeIcon />, },
  { name: 'Channels', icon: <OndemandVideoIcon />, },
  { name: 'Models', icon: <GroupsIcon />, },
];

export const demoThumbnailUrl = 'https://imagedelivery.net/beA4vEIaSpPgFqjqWBwIgQ/64ce30f6-b1b1-4d9e-9ab7-f526eafcee00/public';
export const demoChannelUrl = '/channel/UCmXmlB4-HJytD7wek0Uo97A';
export const demoVideoUrl = '/video/GDa8kZLNhJ4';
export const demoChannelTitle = 'Pleasn Compilation 1.0';
export const demoVideoTitle = 'Best of 2023';
export const demoProfilePicture = 'http://dergipark.org.tr/assets/app/images/buddy_sample.png'