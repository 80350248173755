import React from "react";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, IconButton, InputBase } from "@mui/material";
import { Search } from "@mui/icons-material";

const SearchBar = ({ toggleSearchBar }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchTerm) {
      navigate(`search/${searchTerm}`);
      setSearchTerm("");
      toggleSearchBar();
    }
  }

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{
        background: "#000",
        borderRadius: 20,
        border: "1px solid #3d3d3d",
        pl: 2,
        pr: 2,
        boxShadow: "none",
        display: 'flex',
        alignItems: 'center',
        position: { xs: 'fixed', md: 'static' },
        top: { xs: 66, md: 'auto' },
        left: { xs: '50%', md: 'auto' },
        transform: { xs: 'translateX(-45%)', md: 'none' },
        width: { xs: 'calc(100% - 130px)', md: 'auto' },
        margin: { xs: '0 -15px', md: '0' },
        zIndex: 1000,
      }}
    >
      <InputBase
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
        sx={{
          flex: 1,
          color: "gray",
        }}
      />
      <div sx={{ ml: 'auto' }}>
      <IconButton type="submit" sx={{ p: "8px", color: "gray" }}>
        <Search />
      </IconButton>
      </div>
    </Paper>
  )
}

export default SearchBar;
