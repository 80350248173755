import React from 'react';
import { Typography, Box } from "@mui/material";
import { SpaceBar } from '@mui/icons-material';

function Terms() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: { xs: "16px", md: "150px" },
        ml: { xs: "16px", md: "150px" },
        mr: { xs: "16px", md: "75px" },
        mb: { xs: "64px", md: "0px" }, 
        width: '100%',
        backgroundColor: '#000',
        color: '#fff',
        alignSelf: 'center',
        p: { xs: 2, md: 0 }, 
        overflowY: 'auto', 
        minHeight: { md: 'calc(100vh - 64px)' },
      }}
    >
      <Typography variant="h4" fontWeight="bold" color="#FFF" mb={3}>Terms of Use </Typography> 

      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>
      Effective Date: [26. September 2023]

     </Typography>
      
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Welcome to pleasn.com, an adult video on demand platform dedicated to providing a space for content creators and viewers to connect and share. By accessing or using our platform, you signify your agreement to abide by the following terms and conditions. Please read them carefully before using our services.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>1. Acceptance of Terms </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Your access to and use of this platform is conditioned upon your acceptance of and compliance with these terms. By accessing or using this platform, you affirm that you are at least 18 years of age or the age of majority in your jurisdiction, whichever is higher. These Terms of Use apply to all users of this platform, including but not limited to content providers, viewers, and registered members. If you disagree with any part of the terms, then you do not have permission to access the platform.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>2. User Responsibility and Content </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Users are solely responsible for the content they upload or stream on this platform. It is crucial that all content complies with applicable laws and regulations, and does not infringe upon the rights of third parties. All individuals appearing in the content must be at least 18 years of age at the time the content was created. Users are required to maintain proper age verification records in compliance with 18 U.S.C. § 2257 and 28 C.F.R. § 75, and any other applicable laws and regulations.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>3. Account Security </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Users are responsible for maintaining the confidentiality of their account credentials, including their username and password. It is imperative to report any unauthorized access or use of your account to us immediately. This platform will not be liable for any loss or damage arising from your failure to comply with this section.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>4. Intellectual Property </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      This platform grants you a conditional, revocable, non-transferable license to access and use the platform for personal, non-commercial use, subject to your compliance with these Terms of Use. Unauthorized use, reproduction, or distribution of this platforms intellectual property, including but not limited to trademarks, copyrighted materials, and proprietary information, is strictly prohibited and may result in severe civil and criminal penalties.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>5. Modification of Terms </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We reserve the right, at our sole discretion, to modify or replace these Terms of Use at any time without prior notice. It is your responsibility to check these terms periodically for changes. Your continued use of the platform following the posting of any changes to these terms constitutes acceptance of those changes.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>6. Termination </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We may terminate or suspend your access to the platform without prior notice or liability, for any reason whatsoever, including but not limited to a breach of these Terms of Use. Upon termination, your right to use the platform will cease immediately.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>7. Liability and Indemnification </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      You agree to indemnify and hold harmless this platform, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the platform, including but not limited to, your user submissions, any use of the platform's content, services, and products other than as expressly authorized in these Terms of Use.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>8. Contact </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={20}>

      If you have any questions or concerns regarding these Terms of Use, please contact us using the provided webform.  
      </Typography>


    </Box>
  );
}

export default Terms;
