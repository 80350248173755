import React from 'react';
import { Typography, Box } from "@mui/material";
import { SpaceBar } from '@mui/icons-material';

function TwoTwoFiveSeven() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: { xs: "16px", md: "150px" },
        ml: { xs: "16px", md: "150px" },
        mr: { xs: "16px", md: "75px" },
        mb: { xs: "64px", md: "0px" },
        width: '100%',
        backgroundColor: '#000',
        color: '#fff',
        alignSelf: 'center',
        p: { xs: 2, md: 0 },
        overflowY: 'auto', 
        minHeight: { md: 'calc(100vh - 64px)' },
      }}
    >
      <Typography variant="h4" fontWeight="bold" color="#FFF" mb={3}>2257</Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
        This website is committed to upholding the highest standards of legal compliance and safeguarding against the distribution of child pornography. We take our responsibility seriously and are dedicated to ensuring that all content hosted on our platform complies with federal regulations outlined in 18 U.S.C. § 2257 and 2257A.

        It is important to clarify that this website does not create or produce any sexually explicit content. Rather, we serve as a hosting and distribution platform for content created and provided by independent producers, studios, or individuals. As such, we are exempt from the record keeping requirements. We do not maintain records related to the production of sexually explicit content.

        All producers, studios, and individuals who upload content to our website are required to certify that their content complies with all applicable laws, including the age verification requirements under 18 U.S.C. § 2257 and 2257A. They are solely responsible for maintaining the necessary records and compliance with these federal statutes.

        If you have any concerns about the legality of any content hosted on our platform or require information related to records associated with specific content, we encourage you to contact the content provider directly. They should be able to provide you with the necessary documentation and verification of compliance with federal laws.

        This website is fully committed to cooperating with law enforcement agencies and authorities in any investigation related to potential violations of federal regulations. We will promptly provide access to information and records maintained by content providers to assist with any lawful inquiries.

        We want to emphasize that this website does not condone or tolerate any form of illegal content, including child pornography. We work diligently to prevent the dissemination of such material and to maintain a safe and compliant environment for all users of our platform.

        If you have any questions or concerns regarding our compliance with 18 U.S.C. § 2257 and 2257A, please do not hesitate to contact our legal team.

        Thank you for choosing pleasn.com for your needs. We appreciate your support in maintaining a secure platform for adult content distribution.
      </Typography>
    </Box>
  );
}

export default TwoTwoFiveSeven;