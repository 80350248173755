import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';

const Feed = ({ expandedSidebar, toggleSidebar }) => {
  const { categoryName } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(categoryName ? categoryName.charAt(0).toUpperCase() + categoryName.slice(1) : "Home");
  const [videos, setVideos] = useState([]);
  const [sidebarExpanded, setSidebarExpanded] = useState(expandedSidebar);

  useEffect(() => {
    setSidebarExpanded(expandedSidebar);
  }, [expandedSidebar]);

  const handleToggleSidebar = () => {
    setSidebarExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: "150px",
      height: '100vh',
      width: '100%',
      backgroundColor: '#000',
      color: '#fff',
      textAlign: 'center',
      alignSelf: 'center',
    }}>
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF">stay tuned <br /> for<br /> the industries most premium content.</Typography>
    </div>
  );
}

export default Feed;