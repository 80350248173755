import React from 'react';
import { useParams } from 'react-router-dom';
import { Stream } from '@cloudflare/stream-react';

const VideoDetail = () => {
  const { videoId } = useParams();

  return (
    <div style={{ 
        position: 'relative', 
        width: '100%', 
        height: '500px', 
        marginBottom: '100vh', 
        backgroundColor: 'black' 
      }}>
      <div style={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: '100%', 
          height: '100%', 
          maxWidth: '768px',
          width: '100%', 
          height: '100%' 
        }}>
        <Stream
          controls
          src={videoId}
          letterboxColor="black"
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
};

export default VideoDetail;
