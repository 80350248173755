import React, { useRef, useEffect } from 'react';
import { Stack, Typography, Box } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';

import { categories } from "../utils/constants";

const Sidebar = ({ selectedCategory, setSelectedCategory, expandedSidebar, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarRef = useRef(null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.name);
    if (category.name.toLowerCase() === 'home') {
      navigate('/');
    } else {
      navigate(`/${category.name.toLowerCase()}`);
    }
  };

  const handleOutsideClick = (event) => {
    if (expandedSidebar && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      if (!event.target.classList.contains('menu-icon')) {
        toggleSidebar();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [expandedSidebar]);

  return (
    <Box
      ref={sidebarRef}
      sx={{
        display: { xs: 'flex', md: 'block' },
        position: { xs: 'fixed', md: 'fixed' },
        bottom: { xs: 0, md: 'auto' },
        top: { xs: 'auto', md: '100px' },
        width: { xs: '100%', md: expandedSidebar ? '200px' : '50px' },
        backgroundColor: '#000',
        zIndex: 1000,
        transition: 'width 0.5s ease',
        overflowY: 'hidden',
        overflowX: 'hidden',
        height: { md: 'calc(100vh - 100px)' },
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderTop: { xs: '1px solid #3d3d3d', md: 'none' },
        ml: { md: '15px' },
      }}
    >
      {categories.map((category) => (
        <button
          className="category-btn"
          onClick={() => handleCategoryClick(category)}
          style={{
            background: (selectedCategory ? selectedCategory : 'home').toLowerCase() === category.name.toLowerCase() ? "#000" : undefined,
            color: "white",
            display: "flex",
            alignItems: "center",
            transition: "background-color 0.3s ease",
            width: expandedSidebar ? '100%' : '50px',
            justifyContent: expandedSidebar ? 'flex-start' : 'center',
            '&:hover': {
              backgroundColor: 'grey',
            }
          }}
          key={category.name}
        >
          <span
            style={{
              color: (selectedCategory ? selectedCategory : 'home').toLowerCase() === category.name.toLowerCase() ? "white" : "gray",
              marginRight: expandedSidebar ? "15px" : '0px',
            }}
          >
            {category.icon}
          </span>
          <Typography variant="subtitle2" fontWeight="bold" sx={{ display: { xs: 'none', md: expandedSidebar ? 'block' : 'none' }, 
          opacity: location.pathname.includes(category.name.toLowerCase()) ? 1 : 0.7,
          color: (selectedCategory ? selectedCategory : 'home').toLowerCase() === category.name.toLowerCase() ? "white" : "gray",
          }}>
            {category.name}
          </Typography>
        </button>
      ))}
    </Box>
  );
};

export default Sidebar;
