import React, { useState } from 'react';
import { Stack, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close'; 

import { logo } from "../utils/constants";
import SearchBar from "./SearchBar";

const Navbar = ({ toggleSidebar, selectedCategory, setSelectedCategory }) => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    setSelectedCategory('Home');
    navigate("/");
  };

  const handleMenuIconClick = () => {
    toggleSidebar();
  };

  const toggleSearchBar = () => {
    setShowSearchBar((prev) => !prev);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        background: "linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.9))",
        top: 0,
        zIndex: 100,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ display: { md: 'flex' }, width: '100%' }}
      >
        <Box sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center', flexGrow: 1 }}>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <MenuIcon
              className="menu-icon"
              onClick={handleMenuIconClick}
              style={{ cursor: 'pointer', color: 'white', margin: "15px" }}
            />
          </Box>
          <div
            style={{ 
              cursor: 'pointer', 
              display: 'flex', 
              alignItems: 'center', 
            }} 
            onClick={handleLogoClick}
          >
            <Box 
              component="img"
              src={logo} 
              alt="logo" 
              sx={{ 
                height: 28, 
                marginRight: '20px', 
                marginLeft: { xs: '0px', md: '77px' } 
              }}
            />
            <Typography fontFamily="Zeyada" fontWeight="bold" fontSize={35} color="white">
              pleasn<span style={{ color: "#C94793" }}>.</span>
            </Typography>
          </div>
        </Box>

        <Box 
          sx={{ 
            display: { xs: showSearchBar ? 'block' : 'none', md: 'flex' }, 
            flexGrow: 1.5, 
          }}
        >
          <SearchBar showSearchBar={showSearchBar} toggleSearchBar={toggleSearchBar} />
        </Box>

        <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
          <Box sx={{ display: { xs: showSearchBar ? 'none' : 'block', md: 'none' }, marginRight: 5 }}>
            <SearchIcon
              style={{ cursor: 'pointer', color: 'white' }}
              onClick={toggleSearchBar}
            />
          </Box>
          {/* Display the Close icon when the search bar is open */}
          <Box sx={{ display: { xs: showSearchBar ? 'block' : 'none', md: 'none' }, marginRight: 5 }}>
            <CloseIcon
              style={{ cursor: 'pointer', color: 'white' }}
              onClick={toggleSearchBar}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Navbar;
