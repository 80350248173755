import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        borderTop: "1px solid #3d3d3d",
        marginLeft: { xs: "0px", md: "67px" },
        marginRight: { xs: "0px", md: "27px" },
        textAlign: "center",
        py: 3,
        backgroundColor: "#000",
        position: { xs: 'relative', md: 'static' },
        zIndex: 1,
        paddingBottom: { xs: "80px", md: "25px" },
      }}
    >
      <Typography variant="subtitle2" fontWeight="bold">
        <Link href="/terms" sx={{ margin: 2, color: "gray", textDecoration: "none" }}>Terms</Link>
        <Link href="/privacy" sx={{ margin: 2, color: "gray", textDecoration: "none" }}>Privacy</Link>
        <Link href="/copyright" sx={{ margin: 2, color: "gray", textDecoration: "none" }}>Copyright</Link>
        <Link href="/contact" sx={{ margin: 2, color: "gray", textDecoration: "none" }}>Contact</Link>
        <Link href="/2257" sx={{ margin: 2, color: "gray", textDecoration: "none" }}>2257</Link>
      </Typography>
    </Box>
  );
};

export default Footer;