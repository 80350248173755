import React from 'react';
import { Typography, Box } from "@mui/material";
import { SpaceBar } from '@mui/icons-material';

function Privacy() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: { xs: "16px", md: "150px" },
        ml: { xs: "16px", md: "150px" },
        mr: { xs: "16px", md: "75px" },
        mb: { xs: "64px", md: "0px" }, 
        width: '100%',
        backgroundColor: '#000',
        color: '#fff',
        alignSelf: 'center',
        p: { xs: 2, md: 0 }, 
        overflowY: 'auto', 
        minHeight: { md: 'calc(100vh - 64px)' },
      }}
    >
      <Typography variant="h4" fontWeight="bold" color="#FFF" mb={3}>Privacy Policy </Typography> 

      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>
      Effective Date: [26. September 2023]

     </Typography>
      
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Welcome to pleasn.com. We value the privacy and security of our users. This Privacy Policy is designed to help you understand the nature of the information we collect, how it is used, shared, and safeguarded, as well as the control you have over your information while using our platform.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>1. Information Collection </Typography> 
      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>1.1 Personal Information </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Upon creating an account on this platform, we collect personal information including your full name, email address, and date of birth to verify that you are of legal age to access our content. Additionally, we collect payment information to process transactions on our platform.
      </Typography>

      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>1.2 Usage Information </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We gather data regarding your interactions with our platform. This includes the videos you watch, the search queries you make, and the frequency and duration of your visits. This information helps us understand user preferences and improve our services accordingly.
      </Typography>

      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>1.3 Technical Information </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Technical information is automatically collected to ensure the smooth operation of our platform. This encompasses your IP address, the type and model of your device, the browser type and version you use, and your operating system.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>2. Use of Information </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      The information collected serves various purposes. It enables us to provide, maintain, and enhance our platform, process transactions, and send related information. We also use this information to develop new technologies and to respond to your comments, questions, and requests, monitor and analyze trends, usage, and activities, detect, investigate, and prevent fraudulent transactions and other illegal activities, and comply with legal obligations and enforce our terms and conditions.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>3. Information Sharing </Typography> 
      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>3.1 Service Providers </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We may share your information with third-party service providers who perform functions on our behalf, such as payment processing, data analysis, and email delivery. These service providers are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
      </Typography>

      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>3.2 Legal Compliance </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      In certain circumstances, we may disclose your information if required by law, in response to legal proceedings, or if deemed necessary to protect the rights, property, or personal safety of this platform or its affiliates, and their respective officers, directors, employees, and agents, our users, or the public.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>4. Security </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We are committed to ensuring the security of your information. We employ a variety of security measures including encryption of sensitive information, the use of firewalls to prevent unauthorized access, and Secure Socket Layer (SSL) technology for secure data transmission.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>5. Your Choices </Typography> 
      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>5.1 Account Information </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      You have the right to review, update, or delete your personal information at any time through your account settings on our platform.
      </Typography>

      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>5.2 Communication Preferences </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      You have the option to opt-out of receiving promotional emails from us by following the instructions provided in those emails.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>6. Changes to This Privacy Policy </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by updating the date at the top of this Privacy Policy.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>7. Contact </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={20}>

      If you have any questions or concerns regarding this Privacy Policy, please feel free to contact us using the provided webform.
      </Typography>


    </Box>
  );
}

export default Privacy;
