import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3d3d3d',
    },
    secondary: {
      main: '#3d3d3d',
    },
    text: {
      primary: '#fff',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'gray',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'gray',
          },
          '&:hover fieldset': {
            borderColor: 'gray',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'gray',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#3d3d3d',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#000',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          borderColor: 'gray',
          border: '1px solid',
        },
      },
    },
  },
});

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: 'General',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }
    if (formData.message.length > 500) {
      alert('Message exceeds 500 characters limit.');
      return;
    }
    // Submit form data
    console.log('Form data submitted:', formData);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: { xs: "16px", md: "150px" },
          ml: { xs: "16px", md: "150px" },
          mr: { xs: "16px", md: "75px" },
          mb: { xs: "64px", md: "0px" },
          width: '100%',
          backgroundColor: '#000',
          color: '#fff',
          alignSelf: 'center',
          p: { xs: 2, md: 0 },
          overflowY: 'auto',
          minHeight: { md: 'calc(100vh - 64px)' },
        }}
      >
        <Typography variant="h4" fontWeight="bold" color="#FFF" mb={3}>Contact</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            variant="outlined"
            color="primary"
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            variant="outlined"
            color="primary"
            fullWidth
            margin="normal"
            required
          />
          <FormControl variant="outlined" color="primary" fullWidth margin="normal" required>
            <InputLabel>Subject</InputLabel>
            <Select
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              label="Subject"
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Takedown">Takedown</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            variant="outlined"
            color="primary"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            maxLength="500"
            required
          />
          <Button type="submit" variant="outlined" color="primary" sx={{ mt: 2 }}>
            Submit
          </Button>
        </form>
      </Box>
    </ThemeProvider>
  );
}

export default ContactForm;
