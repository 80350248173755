import React from 'react';
import { Typography } from "@mui/material";

function NotFoundPage() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: "150px",
      height: '100vh',
      width: '100%',
      backgroundColor: '#000',
      color: '#fff',
      textAlign: 'center',
      alignSelf: 'center',
    }}>
      <Typography variant="h2" fontWeight="bold" color="#FFF">404: Oops!</Typography>
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF">The page you are looking for does not exist (yet).</Typography>
    </div>
  );
}

export default NotFoundPage;
