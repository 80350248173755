import React from 'react';
import { Typography, Box } from "@mui/material";
import { SpaceBar } from '@mui/icons-material';

function Copyright() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: { xs: "16px", md: "150px" },
        ml: { xs: "16px", md: "150px" },
        mr: { xs: "16px", md: "75px" },
        mb: { xs: "64px", md: "0px" }, 
        width: '100%',
        backgroundColor: '#000',
        color: '#fff',
        alignSelf: 'center',
        p: { xs: 2, md: 0 }, 
        overflowY: 'auto', 
        minHeight: { md: 'calc(100vh - 64px)' },
      }}
    >
      <Typography variant="h4" fontWeight="bold" color="#FFF" mb={3}>Copyright Policy </Typography> 

      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>
      Effective Date: [26. September 2023]

     </Typography>
      
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We are committed to respecting and protecting intellectual property rights. We have established the following copyright policy to address potential infringements and ensure a safe and lawful environment for our users and content creators.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>1. Takedown Procedure </Typography> 
      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>1.1 Reporting Infringements </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      If you believe that your work has been copied and posted on our platform in a way that constitutes copyright infringement, please provide our Designated Copyright Agent with the following information:<br /><br />

* Identification of the copyrighted work claimed to have been infringed.<br />
* URL(s) of the alleged infringing material to be removed.<br />
* Description of the copyrighted work claimed to have been infringed, along with its location on your site, if available.<br />
* Your contact information: full name, address, email, and phone number.<br />
* A statement affirming your good-faith belief that the use of the material is not authorized by the copyright owner, its agent, or the law.<br />
* A statement, under penalty of perjury, that the information in the notification is accurate and that you are the copyright owner or are authorized to act on behalf of the owner.<br />
* A physical or electronic signature of a person authorized to act on behalf of the copyright owner.
      </Typography>

      <Typography variant="h6" fontWeight="bold" color="#FFF" mb={2}>1.2 Takedown Process </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Upon receipt of a valid takedown complaint, we will promptly remove the alleged infringing material and issue a strike against the user responsible for the infringement. Users receiving two strikes within a 60-day period will have their accounts terminated.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>2. Compliance Measures </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Though based outside the U.S. and Canada, we have voluntarily adopted and implemented universal policies to comply with anti-infringement laws such as the Digital Millennium Copyright Act (DMCA) and the Copyright Modernization Act (CMA).
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>3. User Responsibility </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Users are solely responsible for the content they post on our platform. We urge creators to only upload content they have created or have the necessary authorizations to use. Uploading content that infringes upon the intellectual property rights of others is strictly prohibited.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>4. Claim & Takedown Tools </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We provide a webform for manual submission of copyright notifications. Users are not required to register before utilizing this tool. Alternatively, the email method is also available for reporting infringements.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>5. Enforcement </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      We will remove any material that infringes upon another user’s intellectual property rights. Accounts found to be repeat infringers will be suspended or terminated.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>6. Digital Fingerprinting </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={2}>

      Our platform employs digital fingerprinting technology to identify and block unauthorized attempts to upload copyrighted content. When a match between a reference file and a new upload is detected, a claim is made, and the infringing material is blocked.
      </Typography>

      <Typography variant="h5" fontWeight="bold" color="#FFF" mb={2}>7. Contact </Typography> 
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF" mb={20}>

      For any inquiries or further clarification regarding our copyright policy, please contact us using the provided webform. 
      </Typography>


    </Box>
  );
}

export default Copyright;
