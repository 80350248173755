// src/App.js
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import _ from 'lodash';

import { Navbar, Feed, VideoDetail, ChannelDetail, SearchFeed, Sidebar } from "./components";
import NotFoundPage from "./components/NotFoundPage";
import TwoTwoFiveSeven from "./components/TwoTwoFiveSeven";
import Footer from "./components/Footer";
import Copyright from "./components/Copyright";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import ContactForm from "./components/ContactForm";

import "./index.css";

const App = () => {
  const [expandedSidebar, setExpandedSidebar] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Home');

  const toggleSidebar = () => {
    setExpandedSidebar(prevExpanded => {
      console.log('Previous Expanded:', prevExpanded);
      return !prevExpanded;
    });
  };

  return (
    <BrowserRouter>
      <div className="app-container">
        <Navbar 
          toggleSidebar={toggleSidebar} 
          setExpandedSidebar={setExpandedSidebar} 
          selectedCategory={selectedCategory} 
          setSelectedCategory={setSelectedCategory} 
        />
        <Box className="main-content" sx={{ backgroundColor: "#000", display: 'flex' }}>
          <Sidebar selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} expandedSidebar={expandedSidebar} toggleSidebar={toggleSidebar} />
          <Routes>
            <Route path="/" element={<Feed toggleSidebar={toggleSidebar} expandedSidebar={expandedSidebar} selectedCategory={selectedCategory} />} />
            <Route path="/video/:videoId" element={<VideoDetail />} />
            <Route path="/channel/:id" element={<NotFoundPage />} />
            <Route path="/search/:searchTerm" element={<NotFoundPage />} />
            <Route path="/2257" element={<TwoTwoFiveSeven />} />
            <Route path="/copyright" element={<Copyright />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Box>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
